export default class Fuse {
    constructor(items, { keys }) {
        this.items = items;
        this.keys = keys;
    }

    search(pattern) {
        return this.items.filter(item => {
            return this.keys.some(key => {
                return item[key].includes(pattern);
            });
        });
    }
}
